import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { validateNewTicketInput, validateUpdateTicketInput } from './newTicketValidation';
import { produce } from 'immer';
import axios from 'axios';
import { isEqual } from "lodash"

import service from './Service'

let baseURL = process.env.REACT_APP_BASE_URL

const initialState = {
    newTicket: {
        ticketHead: "",
        ticketDescription: "",
        company: null,
        companyLocation: null,
        status: "todo",
        dueDate: null,
        labels: [],
        priority: "low",
        paymentStatus: "notRequired",
        assignedUsers: [],
        reportedUsers: [],
        proposalBreakUps: [],
        assets: [],
        publishProposal: false,
        publishAndSendProposal: false,
        approvalReason: "",
        uploads: [],
        backDatedCreateDate: null, // To create a ticket with back dated create date
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: false,
        isErrorInTicketCreate: false,
        isSuccessInTicketCreate: false,
        validationErrors: null,
        errorMessage: null,
    },
    existingTicket: {
        _id: "",
        customTicketId: "",
        ticketHead: "",
        ticketDescription: "",
        company: null,
        companyLocation: null,
        status: "",
        dueDate: "",
        labels: [],
        priority: "",
        paymentStatus: "",
        assets: [],
        assignedUsers: [],
        reportedUsers: [],
        proposalBreakUps: [],
        publishProposal: false,
        publishAndSendProposal: false,
        numOfProposalSent: null,
        approvalReason: "",
        approvalRequireFlag: false,
        uploads: [],
        newComment: '',
        comments: [],
        activities: [],
        createdAt: "",
        updatedAt: "",
        isLoading: false,
        isErrorInTicketCreate: false,
        isSuccessInTicketCreate: false,
        validationErrors: null,
        errorMessage: null,
        isErrorInCommentSubmit: false,
        isCommentSubmitInProgress: false
    },
    ticketQuickSearch: {
        isModalOpen: false,
        isFullScreen: true,
        isFilterComponentCollapsed: false,
        isLoading: false,
        isError: false,
        queryString: null,
        status: [],
        company: null,
        assignedUsers: [],
        reportedUsers: [],
        paymentStatus: null,
        dueDateFrom: null,
        dueDateTo: null,
        createdAtFrom: null,
        createdAtTo: null,
        data: [],
        activePage: '',
        totalPages: ''
    },
    aiSuggestionManagement: {
        isLoading: false,
        aiSuggestedTicketHeadings: [],
        isUniqueResultsFromGptModel: false,
        lastRequestQuery: "",
        errorMessage: null
    },
    newTicketModalOpen: false,
    existingTicketModalOpen: false,
    viewOnlyTicketModal: false,
    ticketsForTheBoard: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMessage: null,
};

export const createTicket = createAsyncThunk(
    'tickets/createTicket',
    async (newTicketData, { rejectWithValue }) => {
        try {
            // Validate newTicketData against the schema
            const errors = validateNewTicketInput(newTicketData);
            if (errors?.length) {
                return rejectWithValue(errors)
            } else {
                // Remove unwanted fields
                const {
                    isLoading,
                    isErrorInTicketCreate,
                    isSuccessInTicketCreate,
                    validationErrors,
                    ...cleanedNewTicketData
                } = newTicketData;

                // If validation passes, make the API call
                const result = await service.createNewTicket(cleanedNewTicketData);
                return result;
            }
            // Return the result if successful
        } catch (error) {
            // Validation or API error occurred, reject the thunk with an error value
            return rejectWithValue(error.message);
        }
    }
);


export const updateTicket = createAsyncThunk(
    'tickets/updateTicket',
    async (data , { rejectWithValue }) => {
        try {
            // Validate ticket data against the schema
            const errors = validateUpdateTicketInput(data);
            if (errors?.length) {
                return rejectWithValue(errors)
            } else {
                // Remove unwanted fields
                const {
                    _id,
                    numOfProposalSent,
                    approvalRequireFlag,
                    uploads,
                    newComment,
                    comments,
                    activities,
                    createdAt,
                    updatedAt,
                    isLoading,
                    isErrorInTicketCreate,
                    isSuccessInTicketCreate,
                    validationErrors,
                    errorMessage,
                    isErrorInCommentSubmit,
                    isCommentSubmitInProgress,
                    ...cleanedTicketData
                } = data;

                // If validation passes, make the API call
                const result = await service.updateTicket(_id, cleanedTicketData);
                return result;
            }
            // Return the result if successful
        } catch (error) {
            // Validation or API error occurred, reject the thunk with an error value
            return rejectWithValue(error.message);
        }
    }
)


export const getTicketForBoardView = createAsyncThunk(
    'tickets/getTicketsForBoardView',
    async (_, { rejectWithValue }) => {
        try {
            const result = await service.getTicketForBoardView();
            return result;
        } catch (error) {
            return rejectWithValue(error || 'An error occurred');
        }
    }
);

export const getSavedTicket = createAsyncThunk(
    'tickets/getSavedTicket',
    async (ticketId, { rejectWithValue }) => {
        try {
            const response = await service.getSavedTicket(ticketId);
         
            return response;
        } catch (error) {
            return rejectWithValue(error || 'An error occurred');
        }
    }
)

export const addCommentToTicket = createAsyncThunk(
    'tickets/addComment',
    async ({ ticketId, commentString }, { rejectWithValue }) => {
        try {
            const response = await service.addCommentToTicket(ticketId, commentString);
            return response;
        } catch (error) {
            return rejectWithValue(error || 'An error occurred');
        }
    }
);

export const addInternalCommentsToTicket = createAsyncThunk(
    'tickets/addInternalCommentsToTicket',
    async ({ ticketId, commentString }, { rejectWithValue }) => {
        try {
            const response = await service.addInternalCommentsToTicket(ticketId, commentString);
            return response;
        } catch (error) {
            return rejectWithValue(error || 'An error occurred');
        }
    }
);

export const getAiSuggestionsForTicketHeading = createAsyncThunk(
    'tickets/getAiSuggestionsForTicketHeading',
    async ({ query, isUniqueSuggestionNeeded }, { rejectWithValue }) => {
        try {
            const response = await service.getAiSuggestionsForTicketHeading(query, isUniqueSuggestionNeeded);
            return response;
        } catch (error) {
            return rejectWithValue(error || 'An error occurred');
        }
    }
);

export const getTicketsByQuery = createAsyncThunk(
    'tickets/getTicketsByQuery',
    async ({ data, page, pageSize = 20, last20 }, { rejectWithValue }) => {
        try {
            if (last20) {
                const result = await service.getTicketsByQuery({last20: true});
                return result.data;
            }
            const { ...queryData } = data;
            delete queryData?.data;
            delete queryData?.activePage;
            delete queryData?.totalPages;
            queryData.status = (queryData?.status || []).map((item) => item.value);
            queryData.company = queryData.company?.value || null;
            queryData.assignedUsers = (queryData.assignedUsers || []).map((item) => item._id);
            queryData.reportedUsers = (queryData.reportedUsers || []).map((item) => item._id);
            queryData.paymentStatus = queryData.paymentStatus?.value || '';
            queryData.labels = (queryData.labels || []).map((label) => label.value);
            queryData.page = page;
            queryData.pageSize = pageSize;

            if (data.exportPdf) {
                axios.post(`${baseURL}tickets/getTicketsByQuery`, queryData, {
                    withCredentials: true,
                    responseType: 'blob',
                }).then(response => {
                    // Get the content type from the response headers
                    const contentType = response.headers['content-type'];

                    const blobData = new Blob([response.data], { type: contentType });

                    // Create a URL for the blob data
                    const blobURL = URL.createObjectURL(blobData);

                    // Create a hidden <a> element for downloading the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = blobURL;
                    a.download = 'ticketsByQuery.pdf'; // Use the provided file name
                    document.body.appendChild(a);

                    // Trigger a click event on the <a> element to start the download
                    a.click();

                    // Clean up by removing the <a> element and revoking the blob URL
                    document.body.removeChild(a);
                    URL.revokeObjectURL(blobURL);

                    return { exportPdf: true };
                }).catch(error => {
                    return rejectWithValue('An error occurred while generating PDF.');
                });
            }
            
            const result = await service.getTicketsByQuery(queryData);
            
            return queryData.export ? { result: result, export: true } : result.data;

        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const handleTicketApproval = createAsyncThunk(
    'tickets/handleTicketApproval',
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await service.handleTicketApproval(data.id, data);
            if (response?.success) {
                return response.data;
            } 
        } catch (error) {
            return rejectWithValue(error || 'An error occurred while making the request.');
        }
    }
)


const ticketManagementSlice = createSlice({
    name: 'ticketManagement',
    initialState,
    reducers: {
        // Accepts the name of the state, the field, and the value to handle value change in tickets add or edit modal
        updateFieldValues: (state, actions) => {
            const updateState = actions.payload.state;
            const value = actions.payload.value;
            const field = actions.payload.field;
            if (updateState !== 'mainState') {
                return {
                    ...state,
                    [updateState]: {
                        ...state[updateState],
                        [field]: value,
                    }
                }
            } else {
                return {
                    ...state,
                    [field]: value,
                }
            }
        },

        // Toggle ticket modal open or close
        handleTicketModals: (state, actions) => {
            // actions.payload.modalType is the field either newTicketModalOpen || existingTicketModalOpen
            // setting the state to opposite of its current boolean state
            return {
                ...state,
                [actions.payload.modalType]: !state[actions.payload.modalType]
            }
        },

        // Reset any state by passing the field to the initial state
        resetState: (state, action) => {
            // Create a new state object by resetting the specified field
            state[action.payload.field] = initialState[action.payload.field];
        },

        // Push or update latest ticket changes to the board from Firebase realtime sync
        pushOrUpdateTicketsInTheBoard: (state, action) => {
            const update = action.payload;

            return produce(state, (draftState) => {
                const boardState = draftState.ticketsForTheBoard;

                update.forEach((item) => {
                    const ticketInTheBoardIndex = boardState.findIndex((ticket) => ticket._id === item._id);
                    if (ticketInTheBoardIndex !== -1) {
                        boardState[ticketInTheBoardIndex] = item;
                    } else {
                        boardState.push(item);
                    }
                });
            });
        },
        resetToInitial: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            /******<<<<<< CASES FOR NEW TICKET SAVE >>>>>>******/
            .addCase(createTicket.pending, (state) => {
                state.newTicket.isLoading = true; // Set loading status while making the API call
            })
            .addCase(createTicket.fulfilled, (state, action) => {
                state.newTicket.isSuccessInTicketCreate = true; // Set success status
                state.newTicket.isErrorInTicketCreate = false;        // Clear any previous errors
                state.existingTicket.customTicketId = action.payload.data.customTicketId;
            })
            .addCase(createTicket.rejected, (state, action) => {
                state.newTicket.isLoading = false;
                state.newTicket.isSuccessInTicketCreate = false; // Set success status
                state.newTicket.isErrorInTicketCreate = true;
                if (Array.isArray(action.payload))
                    state.newTicket.validationErrors = action.payload;
                if (typeof (action.payload))
                    state.newTicket.errorMessage = action.payload
            })
            /******<<<<<< CASES FOR UPDATE TICKET >>>>>>******/
            .addCase(updateTicket.pending, (state) => {
                state.existingTicket.isLoading = true;; // Set loading status while making the API call
            })
            .addCase(updateTicket.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.existingTicket = {
                    ...data,
                    approvalRequireFlag: (data.status === 'reqSuperAdminApproval' || data.status === 'reqCustomerAdminApproval'),
                    newComment: '',
                    isLoading: false,
                    isSuccessInTicketCreate: true,
                    isErrorInTicketCreate: false,
                }
            })
            .addCase(updateTicket.rejected, (state, action) => {
                if (Array.isArray(action.payload))
                    state.existingTicket.validationErrors = action.payload;
                if (typeof (action.payload))
                    state.existingTicket.errorMessage = action.payload
                state.existingTicket.isLoading = false
                state.existingTicket.isErrorInTicketCreate = true
                state.existingTicket.isSuccessInTicketCreate = false
            })
            /******<<<<<< CASES FOR GETTING TICKETS FOR BOARD VIEW >>>>>>******/
            .addCase(getTicketForBoardView.pending, (state) => {
                // Only set loading if there are no existing tickets
                if (state.ticketsForTheBoard.length === 0) {
                    state.isLoading = true;
                }
            })
            .addCase(getTicketForBoardView.fulfilled, (state, action) => {
                // Set loading and success/error flags
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                const newData = action.payload?.data || [];

                // Using a fast check for array comparison
                if (!isEqual(state.ticketsForTheBoard, newData)) {
                    state.ticketsForTheBoard = newData; // Only update if different
                } else {
                    console.log('Data is the same; not updating state.');
                }

            })
            .addCase(getTicketForBoardView.rejected, (state, action) => {
                state.errorMessage = action.payload?.message || 'An error occurred';
                state.isSuccess = false;
                state.isError = true;
                state.isLoading = false;
            })
            /******<<<<<< CASES FOR GETTING SAVED TICKET >>>>>>******/
            .addCase(getSavedTicket.pending, (state) => {
                state.existingTicket.isLoading = true;
            })
            .addCase(getSavedTicket.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.existingTicket = {
                    ...data,
                    approvalRequireFlag: (data.status === 'reqSuperAdminApproval' || data.status === 'reqCustomerAdminApproval'), 
                    newComment: '',
                    isLoading: false,
                    isSuccessInTicketCreate: false,
                    isErrorInTicketCreate: false
                }
            })
            .addCase(getSavedTicket.rejected, (state, action) => {
                state.existingTicket.errorMessage = action.payload.message
                state.existingTicket.isLoading = false
                state.existingTicket.isErrorInTicketCreate = true
                state.existingTicket.isSuccessInTicketCreate = false
                state.existingTicketModalOpen = false
            })
            /******<<<<<< CASES FOR ADDING NEW COMMENT >>>>>>******/
            .addCase(addCommentToTicket.pending, (state) => {
                state.existingTicket.isCommentSubmitInProgress = true;
            })
            .addCase(addCommentToTicket.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.existingTicket.isCommentSubmitInProgress = false;
                state.existingTicket.comments = [...state.existingTicket.comments, data];
                state.existingTicket.newComment = "";
            })
            .addCase(addCommentToTicket.rejected, (state, action) => {
                state.existingTicket.isCommentSubmitInProgress = false;
                state.existingTicket.isErrorInCommentSubmit = true;
            })
            /******<<<<<< CASES FOR ADDING INTERNAL COMMENT >>>>>>******/
            .addCase(addInternalCommentsToTicket.pending, (state) => {
                state.existingTicket.isCommentSubmitInProgress = true;
            })
            .addCase(addInternalCommentsToTicket.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.existingTicket.isCommentSubmitInProgress = false;
                state.existingTicket.internalComments = [...state.existingTicket.internalComments, data];
                state.existingTicket.newComment = "";
            })
            .addCase(addInternalCommentsToTicket.rejected, (state, action) => {
                state.existingTicket.isCommentSubmitInProgress = false;
                state.existingTicket.isErrorInCommentSubmit = true;
            })
            /******<<<<<< CASES FOR GETTING AI SUGGESTED TICKET HEADINGS >>>>>>******/
            .addCase(getAiSuggestionsForTicketHeading.pending, (state) => {
                state.aiSuggestionManagement.isLoading = true;
            })
            .addCase(getAiSuggestionsForTicketHeading.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.aiSuggestionManagement.isLoading = false;
                state.aiSuggestionManagement.aiSuggestedTicketHeadings = data.suggestions;
                state.aiSuggestionManagement.lastRequestQuery = data.query;
                state.aiSuggestionManagement.isUniqueResultsFromGptModel = data.isUniqueSuggestion;
            })
            .addCase(getAiSuggestionsForTicketHeading.rejected, (state, action) => {
                state.aiSuggestionManagement.isLoading = false;
                state.aiSuggestionManagement.errorMessage = action.payload.message
            })
            /******<<<<<< CASES FOR TICKETS SEARCH WITH QUERY >>>>>>******/
            .addCase(getTicketsByQuery.pending, (state) => {
                state.ticketQuickSearch.isLoading = true;
            })
            .addCase(getTicketsByQuery.fulfilled, (state, action) => {
                state.ticketQuickSearch.isLoading = false;
                if (action.payload?.export) {
                    state.ticketQuickSearch.csvData = action.payload.result;
                } else if (action.payload?.exportPdf) {
                    state.ticketQuickSearch.exportPdfSuccess = true;
                } else {
                    state.ticketQuickSearch.data = action.payload?.tickets;
                    state.ticketQuickSearch.totalPages = action.payload?.totalPages;
                    state.ticketQuickSearch.activePage = action.payload?.page;
                    state.ticketQuickSearch.loadingFlag = false;
                }
            })
            .addCase(getTicketsByQuery.rejected, (state, action) => {
                state.ticketQuickSearch.isLoading = false;
                state.ticketQuickSearch.isError = true;
            })
            /******<<<<<< CASES FOR HANDLE TICKET APPROVAL >>>>>>******/
            .addCase(handleTicketApproval.pending, (state) => {
                state.existingTicket.isLoading = true;
            })
            .addCase(handleTicketApproval.fulfilled, (state, action) => {
                state.existingTicket = {
                    ...state.existingTicket,
                    isLoading: false,
                    approvalRequireFlag: false,
                    status: action.payload?.status,
                    internalComments: action.payload?.declineReason
                    ?.declineAuthority === 'superAdmin' ? [...state?.existingTicket?.internalComments, action?.payload?.declineReason] : [...state?.existingTicket?.internalComments],
                    comments:  action.payload?.declineReason
                    ?.declineAuthority === 'customerAdmin' ? [...state?.existingTicket?.comments, action?.payload?.declineReason] : [...state?.existingTicket?.comments],
                    errorMessage: null,
                    handleApprovalApiSuccess: true
                }
            })
            .addCase(handleTicketApproval.rejected, (state, action) => {
                state.existingTicket.errorMessage = action.payload.message
                state.existingTicket.isLoading = false
            })
    },
});


export const { updateFieldValues
} = ticketManagementSlice.actions;

export default ticketManagementSlice.reducer;


